import { React, useContext, useEffect, useState, useRef } from "react"
import { CommonContext } from "../contexts/CommonContext"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { AuthContext } from "../contexts/AuthContext"
import ComponentLoader from "../components/ComponentLoader"
import { getDeliveryCharge, getImgMap, logAction } from "../services/api"
import CartOffer from "../assets/cart-offer.png"
import Grid from "@mui/material/Unstable_Grid2"
import ProductCard from "../components/ProductCard"
import NavHeader from "../components/NavHeader"
import TextField from "@mui/material/TextField"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ItemsSummary from "../components/ItemsSummary"
import NaatuIconPoint from "../assets/naatu-point-cart.png"
import Dialog from "@mui/material/Dialog"
import couponModalImg from "../assets/coupon-modal.png"
import {
  getCoupons,
  getUserProductOrders,
  getUserProfileData,
} from "../services/api"
import closeModalImg from "../assets/close-modal.png"
import { useForm } from "react-hook-form"
const styles = {
  cartCont: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
  },
  shadowBox: {
    background: "none",
    // borderRadius : '13px',
    // boxShadow : '0px 0px 10px 0px #eaeaea',
    // padding: "5px",
    // margin: "10px",
    // width:'100%'
  },
  shadowBoxDesk: {
    // background : 'white',
    // borderRadius : '13px',
    // boxShadow : '0px 0px 10px 0px #eaeaea',
    // boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)'
    padding: "5px",
    marginTop: "18px",
    width: "55%",
  },
  cartOfferImg: {
    width: "100%",
    height: "200px",
    marginTop: "10px",
  },
}

function Cart() {
  const navigate = useNavigate()

  const {
    updateCart,
    getCartData,
    clearCart,
    isDesktop,
    cartData,
    getCouponData,
    couponCacheData,
    clearCouponData,
    showLoader,
    hideLoader,
  } = useContext(CommonContext)
  const [openCoupon, setOpenCoupon] = useState(false)
  const [instructions, setInstructions] = useState("")
  const { isUserLoggedIn, getUserId, getCustomerId } = useContext(AuthContext)
  // const [cartData, setCartData] = useState({})
  const [loading, setLoading] = useState(true)
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const naatuPoints = 122
  const [coupons, setCoupons] = useState([])

  const {
    register: registerCoupon,
    handleSubmit: submitCoupon,
    reset: resetCoupon,
    formState: { errors: errorsCoupon },
  } = useForm()
  const { addCouponToCart, showAlert, hideAlert, showSnackbar } =
    useContext(CommonContext)
  useEffect(() => {
    // fetchCartData()
    getUserCoupons()
    logAction("PAGE_VIEW", "cart")
    fetchDeliveryCharge()
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const fetchDeliveryCharge = async () => {
    setDeliveryCharge(await getDeliveryCharge())
  }

  const checkout = async () => {
    if (await isUserLoggedIn()) {
      navigate(`/delivery`, { state: { instructions: instructions } })
    } else {
      navigate("/auth", { state: { navToCart: true } })
    }
  }

  const navToAuth = async () => {
    if (await isUserLoggedIn()) {
      hideAlert()
      showSnackbar("Login successfull, continue to order")
    } else {
      hideAlert()
      navigate("/auth")
    }
  }
  const verifyCoupon = async (couponData) => {
    // showLoader()
    let userCoupon = false
    couponData.couponCode = couponData.couponCode.toUpperCase()
    coupons.map((coupon) => {
      if (coupon.couponCode == couponData.couponCode) userCoupon = coupon
    })

    const resp = await checkCouponEligibility(couponData.couponCode, userCoupon)
    if (!resp) return

    if (couponData.couponCode == "HEALTHYEATS") {
      if (cartData["C090"]) {
        userCoupon = {
          discount_type: "flat",
          couponReduction: 200,
          couponCode: "HEALTHYEATS",
          discount: 200,
        }
      } else {
        showAlert("Coupon not applicable for items in the cart")
        setOpenCoupon(false)
        return
      }
    }

    if (couponData.couponCode == "HEALTHYEGGS") {
      if (cartData["C091"]) {
        const resp = true
        if (resp) {
          userCoupon = {
            discount_type: "flat",
            couponReduction: 150,
            couponCode: "HEALTHYEGGS",
            discount: 150,
          }
        } else {
          setOpenCoupon(false)
          return
        }
      } else {
        setOpenCoupon(false)
        showAlert("Coupon not applicable for items in the cart")

        return
      }
    }

    if (userCoupon) {
      let couponReduction = 0

      if (userCoupon.discount_type == "percentage") {
        if (cartData.totalAmount < userCoupon.minimumPurchaseAmount) {
          setOpenCoupon(false)
          showAlert(
            `This coupon is applicable only for orders above Rs.${userCoupon.minimumPurchaseAmount}/-`
          )

          return
        }

        couponReduction = Math.trunc(
          (cartData.totalAmount / 100) * userCoupon.discount
        )
        couponReduction =
          couponReduction > userCoupon.maxDiscount
            ? userCoupon.maxDiscount
            : couponReduction
      } else if (userCoupon.discount_type == "free_sku") {
        if (cartData[userCoupon.freeSkuAllowedSkus[0]]) {
          updateCart(userCoupon.freeSku, true)
          couponReduction = userCoupon.couponValue
        } else {
          setOpenCoupon(false)
          showAlert("This coupon is not applicable for the items in your cart.")

          return
        }
      } else {
        if (cartData.totalAmount < userCoupon.minimumPurchaseAmount) {
          showAlert(
            `This coupon is applicable only for orders above Rs.${userCoupon.minimumPurchaseAmount}/-`
          )
          setOpenCoupon(false)
          return
        }
        couponReduction = userCoupon.discount
      }

      const addedCoupon = {
        couponCode: userCoupon.couponCode,
        couponValue: couponReduction,
      }

      await addCouponToCart(addedCoupon)
      setOpenCoupon(false)
      showAlert("Coupon Applied Successfully")
    } else {
      setOpenCoupon(false)
      showAlert("Invalid coupon code. Please enter a valid one")
    }
    hideLoader()
  }

  const checkCouponEligibility = (couponCode, userCoupon) => {
    return new Promise(async (resolve, reject) => {
      showLoader()

      if (await isUserLoggedIn()) {
        getUserProfileData(await getUserId())
          .then(async (response) => {
            const orders = await getUserOrders()
            if (couponCode == "HEALTHYEATS" || couponCode == "HEALTHYEGGS") {
              if (orders.length) {
                showAlert(
                  "Invalid Coupon. This coupon is valid only on first order"
                )
                resolve(false)
              } else {
                if (
                  (couponCode == "HEALTHYEATS" && cartData["C090"]) ||
                  (couponCode == "HEALTHYEGGS" && cartData["C091"])
                ) {
                  resolve(true)
                } else {
                  showAlert("Coupon not applicable for items in your cart")
                  resolve(false)
                }
              }
            } else {
              //Check for coupon usage limit
              if (userCoupon?.skuMapping) {
                if (
                  userCoupon.skuMapping
                    .split(",")
                    .filter((sku) => cartData[sku]).length
                ) {
                  resolve(true)
                } else {
                  showAlert("Coupon not applicable for items in your cart")
                  resolve(false)
                }
              } else {
                resolve(true)
              }
            }

            hideLoader()
            setOpenCoupon(false)
          })
          .catch((error) => {
            hideLoader()
            resolve(false)
            setOpenCoupon(false)
          })
      } else {
        hideLoader()
        showAlert(
          <>
            Please signup / login to use this coupon
            <Button
              variant="contained"
              sx={{ marginTop: "10px" }}
              onClick={() => navToAuth()}
            >
              {" "}
              Login / Signup{" "}
            </Button>
          </>
        )
        resolve(false)
      }
    })
  }

  const getUserOrders = async () => {
    const userData = {
      customerId: await getCustomerId(),
      userId: await getUserId(),
    }

    return new Promise(async (resolve, reject) => {
      getUserProductOrders(userData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          resolve(false)
        })
    })
  }

  const getUserCoupons = async () => {
    //TODO
    const params = {
      customerId: "0",
    }
    let resp = await getCoupons(params)
    if (cartData["C090"]) {
      resp.coupons.push({
        couponCode: "HEALTHYEATS",
        couponValue: 200,
        couponTitle: "HEALTHYEATS",
      })
    } else if (cartData["C091"]) {
      resp.coupons.push({
        couponCode: "HEALTHYEGGS",
        couponValue: 150,
        couponTitle: "HEALTHYEGGS",
      })
    }
    setCoupons(resp.coupons)
    setLoading(false)
  }
  const removeCoupon = async () => {
    clearCouponData()
    fetchDeliveryCharge()
  }
  const handleClose = () => {
    setOpenCoupon(false)
  }
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Box sx={{ padding: "4vw" }}>
        {loading ? (
          <ComponentLoader />
        ) : (
          <Box sx={{ marginTop: "7vh" }}>
            <NavHeader />
            <Box
              sx={{
                fontSize: "25px",
                fontFamily: "Foregen",
                textDecoration: "underline #A4243D",
                color: "#404040",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <span>Your Cart</span>

              {/* <Box
                sx={{
                  display: "flex",

                  background: "#A4243D",
                  borderRadius: "50px",
                  padding: "5px 5px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
               
                <Box
                  component="img"
                  src={NaatuIconPoint}
                  alt="points"
                  sx={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    marginRight: "4px",
                    border: "2px solid #FFF7E9",
                    background: "#FFF7E9",
                  }}
                />

              
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    color: "#FFF7E9",
                  }}
                >
                  {naatuPoints} Points
                </span>
              </Box> */}
            </Box>

            {!cartData || Object.keys(cartData).length < 7 ? (
              <Box sx={{ background: "white", padding: "20px", mt: 2 }}>
                No items in your cart
              </Box>
            ) : (
              <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid
                  item
                  style={isDesktop ? styles.shadowBoxDesk : styles.shadowBox}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                >
                  {/* Product List Container */}
                  <ItemsSummary itemDetails={cartData} />
                  {/* <Box sx={{padding:'10px', margin:'10px 0', border:'1px solid lightgreen', color:'green', fontWeight:'bold', textAlign:'center', fontSize:'13px'}}>
              Congratulations! You have saved ₹{cartData.totalDiscount} /-
            </Box> */}

                  {isDesktop ? (
                    <Box
                      sx={{
                        fontSize: "20px",
                        fontFamily: "Foregen",
                        mt: 3,
                        ml: 1,
                        color: "#404040",
                      }}
                    >
                      DELIVERY INSTRUCTIONS
                    </Box>
                  ) : null}

                  <Box
                    sx={{
                      padding: "10px",
                      background: "#FFF7E9",
                      borderRadius: "5px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      placeholder="Add Instructions if any"
                      multiline
                      value={instructions}
                      rows={2}
                      onChange={(event) => {
                        setInstructions(event.target.value)
                      }}
                      fullWidth
                      maxRows={4}
                    />
                  </Box>

                  <Box>
                    {cartData.bogoDiscount ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #F47F13",
                            borderRadius: "10px",
                            justifyContent: "center",
                            margin: "20px 0 0 0",
                            padding: "10px",
                            background: "#F47F13",
                          }}
                        >
                          <Box
                            sx={{
                              color: "white",
                              fontFamily: "Foregen",
                              width: "50%",
                            }}
                          >
                            COUPON APPLIED : BOGO
                          </Box>
                          <Box
                            sx={{
                              color: "white",
                              width: "50%",
                              textAlign: "right",
                              fontSize: "12px",
                            }}
                          >
                            You just got 1Kg FREE Country Chicken
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        {couponCacheData && couponCacheData.couponCode ? (
                          <Box sx={{ padding: "5px 15px" }}>
                            <Box
                              sx={{
                                borderTop: "1px solid #ebebeb",
                                display: "flex",
                                color: "#a4243d",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "10px",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                                onClick={() => removeCoupon()}
                              >
                                Coupon Applied : [ {couponCacheData.couponCode}{" "}
                                ]
                                <HighlightOffIcon
                                  sx={{ fontSize: "15px", marginLeft: "10px" }}
                                />
                              </Box>
                              <Box>
                                {couponCacheData.couponValue ? (
                                  <> ₹ {couponCacheData.couponValue}/- Off </>
                                ) : (
                                  <>Exclusive Offer</>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          // <Box onClick={() => navigate('/applyCoupon')}
                          //   sx={{ padding:'10px', cursor:'pointer', background:'#FFF7E9', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px',
                          //       display:'flex', justifyContent:'space-between', marginTop:'20px', fontFamily:'Foregen', alignItems:'center'}}>
                          //   APPLY COUPONS
                          //   <ChevronRightIcon />
                          // </Box>
                          <form onSubmit={submitCoupon(verifyCoupon)}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                margin: "10px",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Enter coupon code"
                                {...registerCoupon("couponCode", {
                                  required: "Required field",
                                })}
                                error={Boolean(errorsCoupon?.couponCode)}
                                helperText={errorsCoupon?.couponCode?.message}
                                style={{
                                  width: "171px",
                                  height: "21px",
                                  padding: "4px 8px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  fontSize: "14px",
                                  fontFamily: "Arial, sans-serif",
                                  background: "#FFF7E9",
                                }}
                              />

                              <button
                                style={{
                                  height: "30px",
                                  padding: "0 16px",
                                  background: "#A4243D",
                                  color: "#FFF",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontSize: "14px",
                                  fontFamily: "Arial, sans-serif",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  console.log("Apply button clicked")
                                }
                              >
                                Apply
                              </button>
                            </Box>
                          </form>
                        )}
                      </>
                    )}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "10px",
                        color: "#a4243d",
                        fontFamily: "Roboto",
                        marginLeft: "10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={() => {
                          setOpenCoupon(true)
                        }}
                      >
                        View Coupons
                      </div>
                      {/* <div>Redeem your Naatu Coins</div> */}
                    </Typography>
                    <Box
                      sx={{
                        background: "#a4243d",
                        fontFamily:"RobotoSlab",
                        borderRadius: "5px",
                        margin:'10px',
                        padding: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "17px",
                          color: "#FFF0D9",
                          fontWeight: "500",
                          fontFamily:"RobotoSlab",
                          lineHeight: "27px",
                        }}
                      >
                        Price Breakup
                      </Box>

                      <Box
                        sx={{
                          paddingTop: "8px",
                          color: "#FFF0D9",
                          fontWeight: "500",
                          fontFamily:"RobotoSlab",
                          lineHeight: "19px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontFamily:"RobotoSlab",
                            fontSize:'13px'
                          }}
                        >
                          <Box>Sub-Total</Box>
                          <Box>
                            ₹
                            {Number(cartData.totalBasePrice)}
                          </Box>
                        </Box>
                      </Box>

                      {cartData?.bogoDiscount ? (
                        <Box sx={{ paddingTop: "2px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: "13px",
                              color: "#FFF0D9",
                              fontWeight: "500",
                              lineHeight: "19px",
                            }}
                          >
                            <Box>BOGO Discount</Box>
                            <Box>-₹{cartData.bogoDiscount}</Box>
                          </Box>
                        </Box>
                      ) : null}

                      {/* {cartData?.totalDiscount ? (
                        <Box
                          sx={{
                            paddingTop: "1px",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "#FFF0D9",
                            fontWeight: "500",
                            lineHeight: "19px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize:'13px',
                              fontFamily:'RobotoSlab'
                            }}
                          >
                            <Box>Discount on MRP</Box>
                            <Box>-₹{cartData.totalDiscount}</Box>
                          </Box>
                        </Box>
                      ) : null} */}

                      {couponCacheData && couponCacheData.couponCode ? (
                        <Box
                          sx={{
                            paddingTop: "1px",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "#FFF7E9",
                            fontWeight: "500",
                            lineHeight: "19px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                fontSize:'13px',
                              fontFamily:'RobotoSlab'
                              }}
                            >
                              Coupon Discount [ {couponCacheData.couponCode} ]
                            </Box>
                            <Box sx={{ color: "#fff0d9" }}>
                              {couponCacheData.couponValue ? (
                                <>- ₹{couponCacheData.couponValue}</>
                              ) : (
                                <>Exclusive Offer </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ) : null}
                      <Box
                        sx={{
                          paddingTop: "1px",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          color: "#FFF0D9",
                          fontWeight: "500",
                          lineHeight: "19px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize:'13px',
                              fontFamily:'RobotoSlab'
                          }}
                        >
                          <Box>Extras</Box>
                          <Box>₹ {cartData.totalExtras ? cartData.totalExtras : 0}</Box>
                        </Box>
                      </Box>

                      <Box sx={{ padding: "2px 0px" }}></Box>

                      {/* <Box sx={{padding:'8px 0px'}}>
                  <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', fontSize:'11px', opacity:'0.8'}}>
                    <Box>
                      Delivery charges applicable (calculated at checkout)
                    </Box>
                    <Box>
                      ₹{deliveryCharge}
                    </Box>
                  </Box>
                </Box> */}

                      <Box
                        sx={{
                          borderTop: "1px solid #b1b1b1",
                          display: "flex",
                          paddingTop: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontFamily: "Roboto",
                          fontSize: "17px",
                          color: "#FFF0D9",
                          fontWeight: "500",
                          lineHeight: "23px",
                          fontSize:'13px',
                              fontFamily:'RobotoSlab'
                        }}
                      >
                        <Box>Total</Box>
                        <Box sx={{ fontWeight: "bold" }}>
                          ₹{" "}
                          {cartData.totalAmount -
                            (couponCacheData?.couponValue || 0)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ padding: "8px 0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "11px",
                        opacity: "0.8",
                        margin:'0 10px'
                      }}
                    >
                      <Box>
                        *Delivery charges applicable (calculated at checkout)
                      </Box>
                      <Box>{/* ₹{deliveryCharge} */}</Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      background: "#a4243d",
                      height: "72px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bottom: 0,
                      margin:'0 10px',
                      padding: "0px !important",
                    }}
                  >
                    <button
                      style={{
                        height: "50%",

                        background: "#FFF0D9",
                        color: "#A4243D",
                        border: "none",
                        borderRadius: "4px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                      onClick={() => checkout()}
                    >
                      Proceed to Checkout
                    </button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>

      <Dialog
        open={openCoupon}
        onClose={handleClose}
        keepMounted
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            top: "10%",
            boxShadow: "none",
            width: "100%",
            minHeight: "400px",

            margin: "10px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",

            paddingBottom: "20px",
          }}
        >
          <img
            src={closeModalImg}
            onClick={() => {
              setOpenCoupon(false)
            }}
          ></img>
        </Box>

        <Box
          sx={{
            backgroundColor: "#a4243d",
            backgroundImage: `url('${couponModalImg}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          {/* Coupons Grid */}
          <Box
            sx={{
              backgroundImage: { couponModalImg },
              width: "100%",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "30px",
                  fontWeight: "400",
                  color: "#FFF0D9",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Foregen",
                  textDecoration: "underline",
                  marginBottom: "4px",
                }}
              >
                Available Coupons
              </Typography>
            </Box>
            {coupons.map((coupon, index) => {
              if (!coupon.hideCoupon)
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "16px", // Gap between rows
                      gap: "12px", // Gap between items in a row
                    }}
                  >
                    {/* First Item: Coupon Code */}
                    <Box
                      sx={{
                        width: "50%",
                        height: "45px",
                        border: "1px dashed #FFF0D9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#FFF0D9",
                        fontSize: "12px",
                      }}
                    >
                      {coupon.couponCode}
                    </Box>

                    {/* Second Item: Discount */}
                    <Box
                      sx={{
                        width: "20%",
                        height: "30px",
                        fontSize: "11px",
                        fontWeight: 500,
                        lineHeight: "15px",
                        color: "#FFF0D9",
                        display: "flex",
                        alignItems: "center", // Center the text vertically
                      }}
                    >
                      {coupon.discount}
                    </Box>

                    {/* Third Item: Button */}
                    <button
                      style={{
                        width: "40%", // Ensure consistency in size
                        height: "28px",
                        backgroundColor: "#FFF0D9",
                        color: "#a4243d",
                        fontWeight: 600,
                        fontFamily: "Roboto",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        verifyCoupon({ couponCode: coupon.couponCode })
                      }
                    >
                      Tap to Apply
                    </button>
                  </Box>
                )
            })}
          </Box>
        </Box>
      </Dialog>
    </motion.div>
  )
}

export default Cart
