import React, { useState } from "react"
import Box from "@mui/material/Box"
import { Grid, Typography } from "@mui/material"
import ComponentLoader from "../components/ComponentLoader"
import AboutusMain from "../assets/aboutus-main.png"
import AboutusMainOverlay from "../assets/aboutus-main-overlay.png"
import AboutusSeperator from "../assets/aboutus-seperator.png"
import AboutusLine from "../assets/aboutus-line-1.png"
import CCChickenIcon from "../assets/cccchicken-icon.png"
import SaikeshSamiImage from "../assets/saikesh_and_sami_1.png"
import {  styled } from "@mui/system"
import Hygiene from "../assets/aboutus-hygiene.png"
import Quality from "../assets/aboutus-quality.png" // Fixed file name
import Protocol from "../assets/aboutus-protocol.png" // Fixed file name
import Overlay from "../assets/aboutus-overlay.png"
// import BackArrowImg from "../assets/Group.png"
import NavHeader from "../components/NavHeader"
const styles = {
  featImg: {
    width: "250px",
    borderRadius: "15px",
  },
  itemCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "justify",
    maxWidth: "500px",
    fontSize: "18px",
    marginBottom: "30px",
  },
  overlayContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "350px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 auto", // Center alignment for smaller screens
  },
  hygiene: {
    position: "relative",
    width: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 auto", // Center alignment for smaller screens
  },
  portImg: {
    width: "100%",
  },

  overlayImg: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "auto",
    height: "100%",
  },

  overlayImg1: {
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    textAlign: "center",
    width: "auto",
  },
  arrowBtn: {
    width: "14.12px",
    height: "19.79px",
  },

  overlayText: {
    position: "absolute",
    color: "#fff0d9",
    fontSize: "56px",
    textAlign: "center",
    zIndex: 2,
    fontFamily: "Foregen",
    bottom: 0,
    margin: "2px",
  },
  overlayText1: {
    position: "relative",
    color: "#fff0d9",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    zIndex: 1,
    padding: "20px",
    lineHeight: "25px",
    bottom: 0,
    margin: "2px",
    background: "#a4243d",
    marginBottom: "20px",
  },
}

// Styled Components
// const Section = styled(Box)({
//   position: "relative",
//   overflow: "hidden",
//   display: "flex",
//   flexDirection: "column",
//   backgroundColor: "#FFF0D9",
//   flexWrap: "wrap",
//   padding: "20px",
// })

// const BackgroundImage = styled(Box)(({ background }) => ({
//   position: "absolute",
//   top: 0,
//   left: 0,
//   width: "100%",
//   height: "100%",

//   backgroundRepeat: "no-repeat",
//   zIndex: 1,
//   backgroundPosition: "center",
//   backgroundImage: `url(${background})`,
//   backgroundBlendMode: "multiply",
// }))

// const OverlayImage = styled("img")(({ alignRight }) => ({
//   position: "absolute",
//   top: 0,
//   left: 0,
//   width: "100%",
//   height: "100%",
//   zIndex: 1,
//   transform: alignRight ? "scaleX(-1)" : "none", // Flip image horizontally if alignRight is true
// }))

const Title = styled(Typography)(({ alignRight }) => ({
  fontSize: "28px",
  fontFamily: "Foregen, sans-serif",
  color: "#FFF0D9",
  textAlign: alignRight ? "right" : "left",
  zIndex: 1,
  padding: "10px",
  display: "flex",
  justifyContent: alignRight ? "flex-end" : "flex-start",
  marginLeft: alignRight ? "auto" : "0px",
  marginRight: alignRight ? "10px" : "0px",

  paddingRight: "0px",
  marginBottom: "0px",
  paddingBottom: "0px",
  position: "relative",
}))

const Text = styled(Typography)(({ alignRight }) => ({
  fontSize: "14px",
  color: "#fff0d9",
  textAlign: alignRight ? "right" : "left",
  zIndex: 1,
  width: "60%",
  paddingLeft: "10px",
  marginLeft: alignRight ? "auto" : "0px",
  marginRight: alignRight ? "10px" : "0px",
  display: "flex",
  justifyContent: alignRight ? "flex-end" : "flex-start",
  lineHeight: 1.2,
  position: "relative",
}))

const ContentWrapper = styled(Box)({
  backgroundColor: "transparent",
  padding: "40px 0px",
})

const FounderImage = styled("img")({
  width: "100%",

  objectFit: "contain",
  // marginBottom: "20px",
})

const ContentText = styled(Box)({
  maxWidth: "800px",
  padding: "5px",
})

const Title2 = styled(Typography)({
  fontSize: "1.5rem",
  fontFamily: "Foregen",
  color: "#a4243d",
  lineHeight: 1,
  // marginBottom: "20px",
})

const Paragraph = styled(Typography)({
  fontSize: "12px", // Increased font size for better readability
  lineHeight: "1.6",
  color: "#000",
  fontWeight: "400",
  // padding:'10px'
})

const Strong = styled(Typography)({
  fontWeight: "bold",
  color: "#000",
  fontSize: "16px", // Adjusted font size for the strong text
})
// const Strong1 = styled(Typography)({
//   // fontWeight: "bold",
//   color: "#000",
//   fontSize: "12px", // Adjusted font size for the strong text
//   fontWeight: "500",
// })

function AboutUs() {
  const [loading, setLoading] = useState(false)

  const content = [
    {
      title: "Hygiene",
      description:
        "Our farms and processing strictly follow ICAR and NCR meat standards, guaranteeing you the finest quality organic meat.",
      background: Hygiene,
    },
    {
      title: "Quality",
      description:
        "Country Chicken Co's meat is both nutritious and flavorful. Our chickens are raised without antibiotics, added hormones, or steroids.",
      background: Quality,
    },
    {
      title: "Protocol",
      description:
        "At Country Chicken Co, we prioritize the highest standard of care, allowing our chickens to enjoy more time outside, living naturally as they should.",
      background: Protocol,
    },
  ]

  return (
    <Box sx={{ padding: "4vw", marginTop: "5vh", background: "#fff0d9" }}>
      {loading ? (
        <ComponentLoader />
      ) : (
        <Box>
          <NavHeader />
          <Grid
            container
            sx={{ padding: "4vw" }}
            spacing={2}
            justifyContent={{ xs: "center", md: "center" }}
            alignItems={{ xs: "center", md: "center" }}
            direction={{ xs: "column", md: "row" }}
          >
            {/* Image and Overlay */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Box style={styles.overlayContainer}>
                <img
                  src={AboutusMain}
                  alt="How We Started"
                  style={styles.portImg}
                />
                <img
                  src={AboutusMainOverlay}
                  alt="Overlay"
                  style={styles.overlayImg}
                />
                <Box style={styles.overlayText}>About Us</Box>
              </Box>
            </Grid>

            {/* Text Content */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: { xs: "center", md: "justify" },
                  justifyContent: "center",
                }}
              >
                <Box
                  mb={1}
                  sx={{
                    color: "#a4243d",
                    fontFamily: "Foregen",
                    fontSize: "32px",
                  }}
                >
                  OUR HUMBLE BEGINNING
                </Box>
                <Box>
                  <strong>Country Chicken Co</strong> was founded in April 2021
                  with the goal of revolutionizing the chicken industry through
                  its unique approach to naturally raised chicken and eggs.
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  Saikesh and Sami came together to establish this company,
                  aiming to create a healthier future while also supporting
                  farmers in cultivating the finest naturally raised country
                  chickens.
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box style={{ textAlign: "center" }}>
        <img
          src={AboutusSeperator}
          alt="Overlay"
          style={{ width: "335px", marginBottom: "20px" }}
        />
      </Box>

      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <Box style={styles.overlayText1}>
          At Country Chicken Co, we believe that the food you serve your family
          should be as pure and natural as possible. That’s why we’ve made it
          our mission to bring you the best naturally raised meats, sourced from
          over 5000+ responsible farmers across India. ��We’re proud to offer
          chicken, mutton, and eggs that are 100% free from steroids,
          antibiotics, and artificial growth hormones.
          <br />
          <img
            src={AboutusLine}
            alt="Overlay"
            style={{ width: "100px", marginRight: "15px" }}
          />
          <img
            src={CCChickenIcon}
            alt="Overlay"
            style={{ textAlign: "center", margin: "-10px" }}
          />
          <img
            src={AboutusLine}
            alt="Overlay"
            style={{ width: "100px", marginLeft: "15px" }}
          />
        </Box>
      </Grid>
      <Grid container spacing={1}>
        {content.map((item, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            style={{ position: "relative", margin: "0px", overflow: "hidden" }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                paddingBottom: "5%",
              }}
            >
              {/* Background image */}
              <img
                src={item.background}
                alt="Background"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              {/* Overlay image */}
              <img
                src={Overlay}
                alt="Overlay"
                alignRight={item.title === "Quality"}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: item.title === "Quality" ? "scaleX(-1)" : "none",
                }}
              />
              <Title alignRight={item.title === "Quality"}>{item.title}</Title>
              <Text alignRight={item.title === "Quality"}>
                {item.description}
              </Text>
            </div>
            {/* Title and Description */}
          </Grid>
        ))}
      </Grid>

      <ContentWrapper>
        {/* Grid for responsiveness */}
        <Grid
          container
          // spacing={1}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          textAlign={{ xs: "center", sm: "left" }}
          gap={3}
        >
          {/* Image Section */}
          <Grid item xs={12} sm={6} lg={4}>
            <FounderImage src={SaikeshSamiImage} alt="Founding Warriors" />
          </Grid>

          {/* Text Content Section */}
          <Grid item xs={12} sm={6}>
            <ContentText>
              <Title2>Founding Warriors</Title2>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              ></Box>

              <Strong>
                Saikesh Goud,{" "}
                <strong style={{ fontSize: "12px", fontWeight: "500" }}>
                  {" "}
                  Co-Founder-COO
                </strong>
              </Strong>
              <Strong>
                Mohd Sami Uddin,
                <strong style={{ fontSize: "12px", fontWeight: "500" }}>
                  Co-Founder-COO
                </strong>
              </Strong>

              <Paragraph>
                {/* <br /> */}
                <strong>Saikesh Goud, Co-Founder-CEO</strong>, an IIT BHU
                graduate, brings eight years of business experience across
                fourteen states, with expertise in retail and a strong network
                of high-net-worth individuals. He has founded ventures like
                Ploughmen Innovations, Cornquest Foods, and Venagro Nutri Foods.
                Partnering with him is{" "}
                <strong>Mohd Sami Uddin, Co-Founder-COO</strong>, whose five
                years of experience in sales, marketing, and leadership drive
                operational excellence and innovation. Together, they lead with
                a dynamic and complementary approach.
              </Paragraph>
            </ContentText>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Box>
  )
}

export default AboutUs
