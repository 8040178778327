import { useContext, useEffect, useState, useCallback } from "react"
import * as React from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import {
  addCartDataToFb,
  getAllRecipiesData,
  getCustomizedProducts,
  getImgMap,
  getProductData,
  getRecepieVideos,
  logAction,
} from "../services/api"
import Grid from "@mui/material/Unstable_Grid2"
import { CommonContext } from "../contexts/CommonContext"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import { Helmet } from "react-helmet"
import NavHeader from "../components/NavHeader"
import ComponentLoader from "../components/ComponentLoader"
import { Checkbox } from "@mui/material"
import { Capacitor } from "@capacitor/core"
import Recipeies from "./Recipies"
import { AuthContext } from "../contexts/AuthContext"
import ImageSlider from "../components/ImageSlider"
import CustomizeBG from "../assets/menuBg.png"
import addIcon from "../assets/add-icon-light.png"
import removeIcon from "../assets/remove-icon.png"
import CloseButton from "../assets/CloseButton.png"
import AddCartIcon from "../assets/cart-add-icon.png"

const styles = {
  prodImg: {
    // width: "300px",
    // height: "250px",
  },
  productGridCont: {
    margin: "4vw",
    position: "relative",
    justifyContent: "center",
  },
  productGridContDesk: {
    position: "relative",
    height: "min-content",
    margin: "10px",
    padding: "4vw",
    marginTop: "4vh",
  },
  activeExtra: {
    background: "rgba(255, 240, 217, 1)",
    color: "#000",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    boxShadow: "1px 1px 5px 3px #a4243d",
    display: "flex",
    alignItems: "baseline",

    margin: "10px",
    flexDirection: "column",
    // width: "120px",
  },
  inactiveExtra: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "baseline",
    borderRadius: "5px",
    border: "1px solid rgba(255, 240, 217, 1)",
    color: "rgba(255, 240, 217, 1)",

    margin: "10px",
    flexDirection: "column",
    // width: "120px",
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none",
  },
  incCont: {
    display: "flex",
    width: "100%",
  },
  videoItem: {
    padding: "10px",
  },
  discountCont: {
    background: "#a4243d",
    color: "white",
    top: "20px",
    fontSize: "13px",
    boxShadow: "0 0 5px -1px white",
    padding: "5px 8px",
    width: "min-content",
    position: "absolute",
    width: "auto",
    borderRadius: "0 3px 3px 0",
  },
  priceCard: {
    display: "flex",
    // background:'#FFF5E8',
    // boxShadow:'0px 0px 5px 0px rgba(0, 0, 0, 0.15)',
    // padding:'5px',
    marginTop: "10px",
    // border: "1px solid #a4243d ",
    borderRadius: "5px",
    // fontSize:'14px',
    // flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  productItem: {
    margin: "10px",
    background: "#a4243d",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 2px #eaeaea",
    // width: "250px",
    fontFamily: "Foregen",
    display: "flex",
    flexDirection: "column",
    width: "80%",
    alignItems: "center",
    cursor: "pointer",
  },
  recImg: {
    height: "200px",
    width: "150px",
    borderRadius: "5px",
    // width:'100%'
  },
}
function ItemDetail() {
  const navigate = useNavigate()
  const { isDesktop, cartData, updateCart, showPopup } =
    useContext(CommonContext)
  const { getUserMobile } = useContext(AuthContext)
  const { id } = useParams()

  const [anchor, setAnchor] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [skinType, setSkinType] = useState("withskin")
  const [flavourType, setFlavourType] = useState("normal")
  const [boneType, setBoneType] = useState("withBones")
  const [cutType, setCutType] = useState("medium")
  const [productData, setProductData] = useState({})
  const [loading, setLoading] = useState(true)
  const [recipieData, setRecipieData] = useState([])

  const [bogoApplied, setBogoApplied] = useState(false)

  async function addToCart(item) {
    if (getCustomizedProducts().includes(item.id)) {
      setAnchor(true)
      setSelectedItem(item)
    } else {
      updateCart(item, true)
    }

    const userId = await getUserMobile()
    if (userId) {
      let cartObj = {
        platform: Capacitor.getPlatform(),
        itemDetails: item,
      }
      cartObj[item.id] = 1
      addCartDataToFb("" + userId, cartObj)
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setAnchor(open)
  }

  const modifySkinType = (type) => {
    setSkinType(type)
    if (type == "skinless") {
      setFlavourType("normal")
    }
  }

  const modifyBoneType = (type) => {
    setBoneType(type)
  }

  const addItemFromExtras = () => {
    let activeItem = selectedItem
    activeItem.extras = {
      skinType: skinType,
      flavourType: flavourType,
      cutType: cutType,
      boneType: boneType,
    }
    updateCart(activeItem, true)
    setSelectedItem(null)
    setSkinType("withskin")
    setFlavourType("normal")
    setCutType("medium")
    setAnchor(false)
    if (activeItem.enableBogo) handleBogoApplied(true)
  }

  const getProductDetails = async () => {
    const resp = await getProductData({ id: id })
    setProductData(resp)
    if (cartData && cartData[resp.id]?.count && cartData[resp.id]?.enableBogo)
      setBogoApplied(true)
    setLoading(false)
    getRecipiesData()
  }

  const getRecipiesData = async () => {
    const resp = await getAllRecipiesData()
    setRecipieData(resp.recipies)
  }

  const handleBogoApplied = (status) => {
    setBogoApplied(status)
    if (status && !bogoApplied)
      showPopup(
        <>
          <Box
            sx={{
              color: "#a4243d",
              display: "flex",
              textAlign: "center",
              padding: "10px 20px",
            }}
          >
            <Box>
              You just got
              <Box sx={{ fontWeight: "bold", fontSize: "30px" }}>
                1kg FREE Country Chicken !{" "}
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center", margin: "20px 0" }}>
            You have saved ₹{productData.price}
          </Box>
        </>
      )
  }

  const list = (anchor) => (
    <Box sx={{ fontFamily: "Roboto", paddingTop: "20px" }}>
      <Box
        sx={{
          fontSize: isDesktop ? "25px" : "20px",
          fontWeight: "600",
          mx: 1,
          color: "rgba(255, 240, 217, 1)",
          textDecoration: "underline",
          fontFamily: "Foregen",
          textAlign: "center",
        }}
      >
        Customize your Meat
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", padding: "10px", fontFamily:'RobotoSlab' }}>
        {selectedItem?.skinType ? (
          <>
            <Box
              sx={{
                fontFamily: "Foregen",
                fontSize: "13px",
                color: "rgba(255, 240, 217, 1)",
                ml: 1,
                mt: 1
              }}
            >
              Type of Meat
            </Box>
            <Box sx={{ display: "flex", fontSize: "15px" }}>
              <Box
                sx={{ flex: 1 }}
                style={
                  skinType == "withskin"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifySkinType("withskin")}
              >
                With Skin <br />{" "}
                <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>+ ₹0</Box>
              </Box>
              <Box
                sx={{ mr: 3, flex: 1 }}
                style={
                  skinType == "skinless"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifySkinType("skinless")}
              >
                Skinless{" "}
                <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>+ ₹100</Box>
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: "9px",
                color: "rgba(255, 240, 217, 1)",
                ml: 1,
                mb: 1,
              }}
            >
              *Country chicken is preferred with skin, smoked & turmeric for
              best experience.
            </Box>
          </>
        ) : null}
        {selectedItem?.boneType ? (
          <>
            <Box
              sx={{
                fontFamily: "Foregen",
                fontSize: "13px",
                color: "rgba(255, 240, 217, 1)",
                ml: 1,
              }}
            >
              Type of Meat
            </Box>
            <Box sx={{ display: "flex", mb: 0, fontSize: "8px" }}>
              <Box
                sx={{ mr: 1, flex: 1, fontSize:'12px',fontFamily:'RobotoSlab' }}
                style={
                  boneType == "withBones"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifyBoneType("withBones")}
              >
                Skinless{" "}
                <Box sx={{ fontSize: "10px", fontFamily:"RobotoSlab", marginLeft: "5px" }}>+ ₹0</Box>
              </Box>
              <Box
                sx={{ flex: 1, fontSize:'12px',fontFamily:'RobotoSlab' }}
                style={
                  boneType == "boneless"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifyBoneType("boneless")}
              >
                Boneless{" "}
                <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>(+ ₹300)</Box>
              </Box>
            </Box>
          </>
        ) : null}
        {selectedItem?.flavourType ? (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              style={skinType == "withskin" ? null : styles.disabled}
            >
              <Box
                sx={{
                  fontFamily: "Foregen",
                  fontSize: "13px",
                  color: "rgba(255, 240, 217, 1)",
                  ml: 1,
                  mt: 2
                  // mb: 1,
                }}
              >
                Flavour
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px",
                  fontSize: "15px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // maxwidth: "50%",
                    fontSize: "15px",
                    flex: 1,
                  }}
                  style={
                    flavourType == "smoketurmeric"
                      ? styles.activeExtra
                      : styles.inactiveExtra
                  }
                  onClick={() => setFlavourType("smoketurmeric")}
                >
                  Smoked & Turmeric{" "}
                  <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>+ ₹15</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // mb: 1,
                    alignItems: "center",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{ flex: 1 }}
                    style={
                      flavourType == "normal"
                        ? styles.activeExtra
                        : styles.inactiveExtra
                    }
                    onClick={() => setFlavourType("normal")}
                  >
                    Normal{" "}
                    <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>+ ₹0</Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ fontSize: "9px", color: "rgba(255, 240, 217, 1)", ml: 1 }}
              >
                *Country chicken is preferred with skin, smoked & turmeric for
                best experience.
              </Box>
            </Box>
          </>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          // fontSize: "12px",
        }}
      >
        <Box
          sx={{
            fontFamily: "Foregen",
            fontSize: "13px",
            color: "rgba(255, 240, 217, 1)",
            ml: 1,
          }}
        >
          Piece Cut Size
        </Box>
        <Box sx={{ display: "flex", mb: 1, fontFamily:'RobotoSlab'  }}>
          <Box
            sx={{ fontSize: "13px", textAlign: "left", flex: 1, }}
            style={
              cutType == "small" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("small")}
          >
            Small Cut{" "}
            <Box sx={{ fontSize: "10px", marginLeft: "5px" }}>(28-26 pcs)</Box>
          </Box>
          <Box
            sx={{ fontSize: "13px", textAlign: "left", flex: 1 }}
            style={
              cutType == "medium" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("medium")}
          >
            Medium Cut <Box sx={{ fontSize: "10px", textAlign: "left", flex: 1 }}> (20-24 pcs)</Box>
          </Box>
          <Box
            sx={{ fontSize: "13px", textAlign: "left", flex: 1 }}
            style={
              cutType == "biryani" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("biryani")}
          >
            Biryani Cut <Box sx={{ fontSize: "10px", textAlign: "left", flex: 1 }}> (12-16 pcs)</Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "rgba(255, 240, 217, 1)",
          width: "100%",
          borderRadius: "0px 0px 15px 15px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => addItemFromExtras()}
          style={{ margin: "10px", background: "transparent" }}
        >
          Add to Bag{" "}
        </Button>
      </Box>
    </Box>
  )

  useEffect(() => {
    // logAction('PAGE_VIEW', `${productData.name.split(' ').join('-')}`)
    getProductDetails()
  }, [])
  return (
    <Box sx={{ padding: "4vw", marginTop: "4vh" }}>
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          <Helmet>
            <title>{productData.name}</title>
            <meta name="description" content={productData.description} />
          </Helmet>
          <NavHeader />
          <Grid container>
            {/* <Box sx={{padding:'10px', border:'1px solid #eaeaea', boxShadow:'0 0 5px 5px #eaeaea'}}> */}
            <Grid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              style={
                isDesktop ? styles.productGridContDesk : styles.productGridCont
              }
            >
              {/* <Box sx={styles.discountCont}>
 {Math.trunc(((productData.mrp - productData.price) / productData.mrp) * 100)}% Off
 </Box> */}
              <Box
                sx={{
                  textAlign: "center",
                  // height: "250px",
                  position: "relative",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ImageSlider imgUrls={productData.imgUrls} />
              </Box>

              {/* <img src={ isDesktop ? (productData.desktopImgUrls ? productData.desktopImgUrls[0] : productData.imgUrls[0]) : productData.imgUrls[0]} style={styles.prodImg}/> */}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={
                isDesktop ? styles.productGridContDesk : styles.productGridCont
              }
            >
              <Box
                sx={{ fontSize: "30px", mt: 1, fontFamily: "Foregen" }}
              >
                {productData.name}
              </Box>

              <Box
                sx={{
                  color: "rgba(135, 135, 135, 1)",
                  fontWeight: "400",
                  fontFamily:'RobotoSlab',
                  fontSize: "12px",
               
                }}
              >
                {productData.style ? `${productData.style}` : null}
                {productData.aka ? (
                  <Box
                    sx={{
                      display: "flex",
                      fontSize: "12px",
                      fontFamily:'RobotoSlab',
                      color: "rgba(135, 135, 135, 1)",
                      marginTop:'3px'
                    }}
                  >
                    <Box>Aka :</Box>
                    <Box sx={{ width: "250px" }}>&nbsp;{productData.aka}</Box>
                  </Box>
                ) : null}
              </Box>
              {/* <Box sx={{fontSize:'17px', mb:1}}>
 {productData.qty}
 </Box> */}

              {productData.livePrice ? null : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    mb: 1,
                    borderBottom: "1px solid #afa5a5",
                    paddingBottom: "15px",
                  }}
                >
                  {/* <Box sx={{fontSize:'15px', mr:1}}>
 <s>₹ {productData.mrp}</s> 
 </Box> */}
                  <Box
                    sx={{
                      fontSize: "25px",
                      mr: 1,
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    ₹ {productData.price}
                    <Box
                      sx={{
                        fontSize: "13px",
                        fontWeight: "100",
                        marginLeft: "2px",
                      }}
                    >
                      /{productData.qty}
                    </Box>
                  </Box>
                  {/* <Box sx={{fontSize:'15px', marginLeft:'5px', color:'#f47f13'}}>
 {Math.trunc(((productData.mrp - productData.price) / productData.mrp) * 100)}% Off
 </Box> */}
                </Box>
              )}

              {/* {
 productData.preferredBy ?
 <Box sx={{display:'flex', marginTop:'2px', fontSize:'14px', color:'#404040'}}>
 <Box>Preferred By :</Box>
 <Box>&nbsp;{productData.preferredBy}</Box> 
 </Box> : null
 } */}

              {productData.age ? (
                <Box
                  sx={{
                    display: "flex",
                    color: "rgba(135, 135, 135, 1)",
                    fontWeight: "400",
                    fontFamily:'RobotoSlab',
                    fontSize: "12px",
                    mb: 1,
                    marginTop:'4px'
                  }}
                >
                  <Box>Age :</Box>
                  <Box>&nbsp;{productData.age}</Box>
                </Box>
              ) : null}
              {/* {
 productData.dishes ?
 <Box sx={{ marginTop:'2px', fontSize:'14px', color:'#404040'}}>
 Suggested Dishes : &nbsp;{productData.dishes}
 </Box> : null
 } */}
              {productData.healthBenefits ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily:'RobotoSlab',
                    marginTop: "10px",
                    fontSize:'13px',
                    mb:2
                  }}
                >
                  <Box>Health Benefits :</Box>
                  <Box>{productData.healthBenefits}</Box>
                </Box>
              ) : null}

              {/* {
 productData.livePrice ? 
 <>
 <Box sx={{display:'flex', borderTop:'1px solid #404040', paddingTop:'15px', margin:'10px 0 0 0', color:'#404040',fontSize:'14px'}}>
 <Box sx={{marginRight:'20px'}}>
 Live Weight*: 1.5kg 
 </Box>
 <Box>
 Meat Weight*: 900gms - 1000gms
 </Box>
 </Box>
 </> : null
 } */}

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {productData.livePrice ? (
                  <>
                    <Box
                      sx={{
                        width:'100%',
                        maxWidth: "400px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width:'100%'
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            border: "1px solid #a4343d",
                            background: "rgba(164, 36, 61, 0.1)",
                            borderTopLeftRadius: "7px",
                            width:'25%',
                            borderBottomLeftRadius: "7px",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "16px",
                              fontFamily: "Foregen",
                              fontWeight: "500",
                              marginTop: "15px",
                              marginBottom: "15px",
                              marginLeft: "10px",
                              marginRight: "5px",
                              flex: 1,
                            }}
                          >
                            Live Bird
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            borderTop: "1px solid #a4243d",
                            borderRight: "1px solid #a4243d",
                            textAlign: "center",
                            marginRight: "0px",
                            width:'40%',
                            borderBottom: "1px solid #a4243d",
                            background: "rgba(164, 36, 61, 0.1)",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "Foregen",
                              padding: "14px",
                            }}
                          >
                            ₹{productData.livePrice}/KG
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            textAlign: "left",
                            borderTopRightRadius: "7px",
                            borderBottomRightRadius: "7px",
                            width: "30%",
                            border:'1px solid grey',
                            borderLeft:'0px',
                            background: "rgba(113, 113, 113, 0.1)"
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "9px",
                              paddingLeft:'10px',
                              // width: "100%",
                              textAlign: "left",
                              // width:'70%',
                              marginLeft: "5px",
                              flex: 2,
                              // marginRight: "10px",
                              padding:'9px'
                            }}
                          >
                            {/* <Box sx={{fontSize:'20px', fontWeight:'bold'}}>
 ₹{productData.price}/{productData.qty}
 </Box>
 <Box sx={{fontSize:'10px', color:'black', opacity:'0.3'}}>
 Apply coupon and get 1kg meat FREE
 </Box> */}
                            Live bird is available only in stores.
                          </Box>
                        </Box>

                        {/* <Box sx={{display:'flex', flexDirection:'column', width:'30%'}}>
 <Box sx={{fontSize:'20px', fontWeight:'bold'}}>
 ₹{productData.livePrice * 1.5}/kg
 </Box>
 <Box sx={{fontSize:'10px'}}>
 Available at stores
 </Box>
 </Box> */}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        minWidth: "max-content",
                        marginTop: "5px",
                        maxWidth: "400px"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          background: "rgba(164, 36, 61, 0.1)",
                          border: "1px solid #a4243d",
                          borderRadius: "7px",
                          mt:1,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width:'23%'
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "16px",
                              fontFamily: "Foregen",
                              fontWeight: "500",
                              marginTop: "15px",
                              marginBottom: "15px",
                              marginLeft: "15px",
                              marginRight: "16px",
                            }}
                          >
                            Meat
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            borderLeft: "1px solid #a4243d",
                            borderRight: "1px solid #a4243d",
                            padding: "15px",
                            textAlign: "center",
                            marginRight: "5px",
                            width:'32%',
                            display: "flex",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "Foregen",
                              flex: 1,
                            }}
                          >
                            {/* <Box sx={{fontSize:'15px'}}>
 Live Bird : 
 </Box>
 <Box sx={{fontSize:'13px'}}>
 1.5Kg = 900gms - 1000gms Meat
 </Box> */}
                            ₹{productData.price}/{productData.qty}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            // display: "flex",
                            alignItems: "center",
                            textAlign: "left",
                            width:'30%'
                            // marginLeft: "5px",
                            // width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "9px",
                              // width: "100%",
                              textAlign: "left",
                              fontFamily:'RobotoSlab',
                              // width:'100%',
                              // marginLeft: "-5px",
                              flex: 1,
                              paddingLeft:'10px'
                            }}
                          >
                            {/* <Box sx={{fontSize:'20px', fontWeight:'bold'}}>
 ₹{productData.price}/{productData.qty}
 </Box>
 <Box sx={{fontSize:'10px', color:'black', opacity:'0.3'}}>
 Apply coupon and get 1kg meat FREE
 </Box> */}
                            Gross wt : 1500gms <br /> Net wt : 900-1000gms
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : null}

                {productData.enableBogo ? (
                  <>
                    <Box sx={{ textAlign: "center" }} mt={2}>
                      {bogoApplied
                        ? "Enjoy 1kg FREE Meat"
                        : "Buy 1Kg Meat and get 1Kg Meat FREE"}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        border: "1px solid #F47F13",
                        borderRadius: "10px",
                        justifyContent: "center",
                        margin: "10px 0 0 0",
                        background: bogoApplied ? "#F47F13" : null,
                      }}
                    >
                      <Box>
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: bogoApplied ? "white" : "#F47F13",
                            },
                          }}
                          checked={bogoApplied}
                          onChange={(event) =>
                            handleBogoApplied(event.target.checked)
                          }
                        />
                      </Box>
                      <Box sx={{ color: bogoApplied ? "white" : "#F47F13" }}>
                        {bogoApplied ? "Coupon Applied" : "Apply Coupon"} : BOGO
                      </Box>
                    </Box>
                  </>
                ) : null}

                <Box
                  sx={{
                    marginTop: "25px",
                  }}
                >
                  {cartData &&
                  cartData[productData.id] &&
                  cartData[productData.id].count ? (
                    <Box style={styles.incCont}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center", // Align items vertically
                          borderRadius: "5px",
                          marginRight: "10px",
                          background: "#a4243d",
                          justifyContent: "center",
                          // padding: "5px",
                          width: "80%",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => navigate("/cart")}
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                            background: "transparent",
                            color: "rgba(255,246,233,1) !important",
                            flex: 1,
                          }}
                        >
                          <img
                            src={AddCartIcon}
                            alt="Add Icon"
                            style={{
                              height: "100%",
                              margin: "5px",
                            }}
                          />
                          View Bag
                        </Button>

                        {/* Decrease Button */}
                        <Box
                          onClick={() => updateCart(productData, false)}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            // backgroundColor: "rgba(255, 246, 233, 1)",
                            cursor: "pointer",
                            marginLeft: "12px",
                          }}
                        >
                          <img
                            src={removeIcon}
                            alt="Remove Icon"
                            style={{
                              height: "100%",
                              width: "15px",
                              color: "#a4243d",
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#a4243d",
                            color: "white",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                          }}
                        >
                          {cartData[productData.id].count}
                        </Box>

                        {/* Increase Button */}
                        <Box
                          onClick={() => updateCart(productData, true)}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            // backgroundColor: "rgba(255, 246, 233, 1)",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={addIcon}
                            alt="Add Icon"
                            style={{
                              height: "100%",
                              width: "15px",
                              color: "#a4243d",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {productData.stockQty == 0 ? (
                        <Button variant="outlined" fullWidth disabled>
                          Out of stock
                        </Button>
                      ) : (
                        <>
                          {productData?.onlyAtStores ? (
                            <Button variant="outlined" fullWidth disabled>
                              Available at stores
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => addToCart(productData)}
                            >
                              Add to bag & Customize
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                </Box>

                {/* <Box style={styles.priceCard}>
 
 <Box sx={{display:'flex', marginBottom:'15px'}}>
 <Box sx={{display:'flex', borderRight:'1px solid #404040'}}>
 <Box sx={{width:'min-content', marginRight:'15px'}}>
 Live Weight
 </Box>
 <Box sx={{marginRight:'10px'}}>
 1.5Kg
 </Box>
 </Box>
 <Box sx={{display:'flex'}}>
 <Box sx={{width:'min-content', marginLeft:'10px', marginRight:'15px'}}>
 Meat Weight
 </Box>
 <Box>
 0.9Kg
 </Box>
 </Box>
 </Box>
 

 <Box sx={{color:'#a4243d', fontSize:'15px', marginBottom:'10px'}}>
 Final Price - 1.5Kg x ₹299:₹449
 </Box>

 <Button variant='contained' fullWidth
 onClick={() => addToCart(productData)}>Add To Cart</Button>
 </Box>
 

 */}
              </Box>
              {/* 
 <Box sx={{whiteSpace:'pre-line', textAlign:'justify', wordBreak:'break-word', marginTop:'10px'}}>
 {productData?.description}
 </Box> */}
            </Grid>
            {/* </Box> */}
          </Grid>
        </>
      )}

      {id == "better-than-broiler-chicken" ? (
        <>
          {isDesktop ? (
            <img
              src="https://countrychickenco.in/builds/images/why-btb-desk.png"
              style={{ width: "100%" }}
            />
          ) : (
            <img
              src="https://countrychickenco.in/builds/images/why-btb-mob.png"
              style={{ width: "100%" }}
            />
          )}
        </>
      ) : null}

      {isDesktop ? (
        <Box>
          <Box
            sx={{
              fontSize: "25px",
              marginTop: "30px",
              marginBottom: "0px",
              marginLeft: "10px",
              color: "#a4243d",
              fontFamily: "Foregen",
            }}
          >
            RECOMMENDED RECIPES
          </Box>
          {/* {
 getRecepieVideos().map((video, index) => {
 return(
 <Grid xs={12} sm={12} md={3} lg={3} key={index}>
 <Box key={index} style={styles.videoItem}>
 <div class="itemdet-recipie-iframe">
 <iframe
 src={video.url}>
 </iframe>
 </div>
 </Box>
 </Grid>
 )
 })
 } */}
          <Box sx={{ display: "flex", width: "100%", overflowX: "scroll" }}>
            {recipieData.map((recipie, index) => {
              return (
                <Box
                  key={index}
                  style={styles.productItem}
                  onClick={() => navigate(`/recipieDetails/${recipie.id}`)}
                >
                  <img src={recipie.imgUrl} style={styles.recImg} />
                  <Box
                    sx={{
                      padding: "10px 5px",
                      color: "#FFF0D9",
                      textAlign: "center",
                      fontSize: "20px",
                      width: "60%",
                    }}
                  >
                    {recipie.title}
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      ) : null}

      <React.Fragment key={"bottom"}>
        <Drawer
          ModalProps={{
            BackdropProps: {
              sx: {
                // background: "none !important", // Removes the backdrop
                boxShadow: "none",
              },
            },
          }}
          PaperProps={{
            sx: {
              background: "none !important", // Your custom drawer background
              padding: "10px",
              margin: "20px",
              height: "auto",
              boxShadow: "none",
            },
          }}
          anchor={isDesktop ? "right" : "bottom"}
          open={anchor}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "none",
              padding: "10px",
              margin: "20px",
              height: "auto",
              marginTop: isDesktop ? "100px" : "none",
            },
          }}
        >
          {/* Close Button (only appears if not desktop) */}

          {/* Drawer Content */}
          {!isDesktop && (
            <img
              src={CloseButton}
              alt="close button"
              style={{
                top: "50px",
                zIndex: 2,
                width: "28px",
                margin: "10px",
                alignSelf: "center",
                right: "45%",
                height: "30px",
                cursor: "pointer", // Makes it clear the icon is clickable
              }}
              onClick={toggleDrawer(false)} // Close the drawer on click
            />
          )}
          <Box
            sx={{
              backgroundImage: `url(${CustomizeBG})`,
              borderRadius: "20px",
              overflow: "visible",
            }}
          >
            {list("bottom")}
          </Box>
        </Drawer>
      </React.Fragment>
    </Box>
  )
}

export default ItemDetail
